import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const Treebar = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/treebar/treebar-1.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/treebar/treebar-2.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/treebar/treebar-3.png`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "Tree Bar Central Gurney 1",
        "Tree Bar Central Gurney 2",
        "Tree Bar Central Gurney 3",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Tree Bar" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Tree Bar</h2>
                        <p className="font-light text-gray-600">Sun – Thu 12pm – 12am / Fri & Sat 12pm – 1am</p>
                        <p className="font-light text-gray-600">For enquiries: 04-238 0000</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">       
                            Tree Bar is an iconic popular hotspot to gather with friends and family, a place to see and be seen. Nestled beneath the shade of a 100-year old Angsana tree, the bar seamlessly blends nature with an al-fresco experience. Tree bar is noted for its wide selection of value-for-money varieties of beers, refreshing drinks, mouth-watering bites and great music to chill out or have a party to celebrate. The venue also features several TV screens for sports enthusiasts, ensuring that you can catch your favourite games, matches and races. Whether you're looking for a casual hangout or a place to socialize, Tree Bar is the perfect choice to immerse yourself in an atmosphere where relaxation, gathering and great drinks all come together. 
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-9-treebar.jpg`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default Treebar
